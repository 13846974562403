<template>
    <!-- nav -->
    <nav class="nav">
        <div class="nav__wrap">
            <a class="nav__btn">
            </a>
            <p class="nav__item">
                <span class="nav__text">
                    {{t('10764')}}
                </span>
            </p>
            <a class="nav__btn">
            </a>
        </div>
    </nav>
    <!-- //nav-->
    <div id="container" class="container">
        <div id="sub" class="sub quicksearch">
            <!-- search-form -->
            <section class="search-form">
                <div class="box">
                    <div class="search-form__wrap">
                        <input 
                            type="text" 
                            class="ipt ipt--muted" 
                            ref="searchBox" 
                            :placeholder="t('10836')" 
                            v-model="searchText" 
                            @keyup.enter="$refs.searchBox.blur();onClickSearch();" 
                        ><!-- 재번역 체크 -->
                        <button type="submit" class="btn-search" @click="onClickSearch">
                            <i class="icon icon-search"></i>
                        </button>
                    </div>
                </div>
            </section>
            <!-- category -->
            <section class="listsearch">
                <div class="listsearch__wrap">
                    <div class="listsearch__inner">
                        <router-link to="/layers/date" custom v-slot="{ navigate }" >
                            <div class="listsearch__box" @click="navigate">
                                <span class="listsearch__icon">
                                    <i class="icon icon-date"></i>
                                </span>
                                <input v-if="dateRangeText !== null" type="text" class="listsearch__ipt" :value="dateRangeText" readonly> 
                            </div>
                        </router-link>
                    </div>
                    <div class="listsearch__inner">
                        <router-link to="/layers/member" custom v-slot="{ navigate }" >
                            <div class="listsearch__box" @click="navigate">
                                <span class="listsearch__icon">
                                    <i class="icon icon-member"></i>
                                </span>
                                <input v-if="selectedMember !== null" type="text" class="listsearch__ipt" :value="`성인 ${selectedMember.adultCount}명, 아동 ${selectedMember.childCount}명`" readonly>
                            </div>
                        </router-link>
                    </div>
                </div>
            </section>
            <!-- content -->
            <section class="content">
                <div class="row">
                    <!-- 인기검색어 -->
                    <div class="quicksearch__item topsearch">
                        <h3 class="quicksearch__title">{{t('10573')}}</h3>
                        <ul class="quicksearch__topsearched grey1" v-if="'02' in mmy01421">
                            <li v-for="(item, index) in mmy01421['02']" :key="item.SEQ">
                                <strong class="skyblue">{{index + 1}}</strong>
                                <a  @click="quickonClickSearch(item.SEARCH_WORD)">
                                    {{item.SEARCH_WORD}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- 최근검색어 -->
                    <div class="quicksearch__item recentsearch">
                        <div class="quicksearch__titlewrap flex">
                            <h3 class="quicksearch__title">{{t('10574')}}</h3>
                            <button type="button" id="btn-removetext" class="btn-removetext" @click="handleOpenMessage">
                                {{t('10411')}}
                            </button>
                        </div>
                        <!-- recentsearch-list -->
                        <div class="tag">
                            <ul class="tag__list">
                                <li v-for="(item, index) in searchItems" :key="index">
                                    <span class="tag__text">
                                        <span @click="onClickSearchItem(item)">
                                        {{item}}
                                        </span>
                                        <button type="button" class="btn-historyclose" @click="onClickRemoveItem(index)">
                                            <i class="icon icon-historyclose"></i>
                                        </button>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <!-- //content -->
        </div>
    </div>
    <ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
    <HistoryDelete v-if="openMessage" :handleClose="handleCloseMessage" :handleSubmit="handleSubmit"/>
    <TheNavigation></TheNavigation>
  <TheTop/>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { useRouter } from 'vue-router';
import HistoryDelete from '@/components/layers/Message_historydelete';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
    components: {		
        ErrorAlert,
        HistoryDelete
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const openErrorAlert = ref(false)
        // const userData = computed(() => store.state.userData);
        // const isLogin = computed(() => Object.keys(userData.value).length !== 0);
        const mmy01421 = computed(() => store.state.mmy01.mmy01421);
        const searchItems = computed(() => store.state.search.searchItems);

        const openMessage = ref(false);
        const searchText = ref("");

        const goBack = () => {
            router.go(-1);
        };

        const onClickRemoveItem = async (index) => {
            store.commit("search/REMOVE_SEARCH_ITEM", { seq: index});
        };

        const onClickSearch = () =>{
            if(searchText.value.length === 0) {
    			store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "검색어를 입력해 주세요."});
				openErrorAlert.value = true;
                //alert("검색어를 입력해 주세요");
                return;
            }
            store.commit("search/ADD_SEARCH_ITEM", {searchItem: searchText.value});
            router.push({
                path: "/search/rooms",
                query: {
                    search: searchText.value
                }
            });
        };

        const quickonClickSearch = (searchtext) =>{
            router.push({
                path: "/search/rooms",
                query: {
                    search: searchtext
                }
            });
        };

        const onClickSearchItem = (searchText) =>{
            router.push({
                path: "/search/rooms",
                query: {
                    search: searchText
                }
            });
        };

        onMounted(()=>{
            store.dispatch("mmy01/fetchMmy01421",{
                proc_cd: "02",
            });
            if(store.state.mhm01.selectedDateRange === null) {
                store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
            }
            if(store.state.mhm01.selectedMember === null) {
                store.commit('mhm01/SET_MEMBER', {member: {adultCount: 2, childCount: 0}});
            }
        });
        const { t }= useI18n() //번역필수 모듈

        return {
            dateRangeText: computed(() =>
                store.state.mhm01.selectedDateRange !== null
                ? dayjs(store.state.mhm01.selectedDateRange.start).format(
                    "MM.DD(dd)"
                    ) +
                    "~" +
                    dayjs(store.state.mhm01.selectedDateRange.end).format("MM.DD(dd)")
                : null
            ),
            selectedMember: computed(() => store.state.mhm01.selectedMember),
            handleOpenMessage: () => {
                openMessage.value = true;
            },
            handleCloseMessage: () => {
                openMessage.value = false;
            },
            handleSubmit: async () => {
                store.commit("search/CLEAR_SEARCH_ITEM");
                openMessage.value = false;
            },
            openMessage,
            onClickRemoveItem,
            goBack,
            searchText,
            onClickSearch,
            quickonClickSearch,
            mmy01421,
            searchItems,
            onClickSearchItem,
            openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
            t,
            i18n
        }
    },
}
</script>